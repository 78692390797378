import { init } from "@web3-onboard/vue";
import injectedModule from "@web3-onboard/injected-wallets";

export function onboardInit() {
  const injected = injectedModule();
  const infuraKey = process.env.VUE_APP_INFURA_KEY;
  const infuraUrl = process.env.VUE_APP_INFURA_URL;
  const rpcUrl = `${infuraUrl}${infuraKey}`;

  const web3Onboard = init({
    wallets: [injected],
    chains: [
      {
        id: "0x4",
        token: "ETH",
        label: "Ethereum Rinkeby",
        rpcUrl,
      },
    ],
  });
  return web3Onboard;
}

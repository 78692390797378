<template>
  <div class="row">
    <div class="col-12">
      <h1 class="text-center">Trading Bots - POC web3</h1>
      <small><b>v1.0.0</b></small>
      <div>
        <span v-if="connectedWallet"
          >Connected Wallet: {{ connectedWallet.label }}</span
        >
      </div>
    </div>
    <div class="col-12">
      <div>
        <div class="d-flex flex-row">
          <div class="flex-fill">
            <h3 class="text-start">Wallet connection</h3>
          </div>
          <div>
            <button class="btn btn-primary" type="button" @click="connect">
              Connect to a Wallet
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <h3 class="text-start">Start next round</h3>
          </div>
          <div>
            <button class="btn btn-primary" type="button" @click="nextRound">
              CALL
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <h3 class="text-start">Mint NFT</h3>
          </div>
          <div>
            <button class="btn btn-primary" type="button" @click="buyNFT">
              CALL
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <div class="d-flex flex-row">
              <div class="me-3"><h3 class="text-start">Lottery</h3></div>
              <div class="mx-1">
                <input
                  type="text"
                  v-model="d1Address"
                  placeholder="First address for donation"
                  class="form-control"
                />
              </div>
              <div class="mx-1">
                <input
                  type="text"
                  v-model="d2Address"
                  placeholder="second address for donation"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary" type="button" @click="lottery">
              CALL
            </button>
          </div>
        </div>

        <hr />
        <div v-if="connectedWallet">
          <div class="d-flex flex-row">
            <div class="flex-fill">
              <div class="d-flex flex-row">
                <div class="me-3">
                  <h3 class="text-start">Connected Wallet NFTs</h3>
                </div>
                <div id="connectedWalletNFTs" class="mx-1"></div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <!-- <div class="d-flex flex-row">
          <div class="flex-fill">
            <div class="d-flex flex-row">
              <div class="me-3"><h3 class="text-start">BalanceOf</h3></div>
              <div class="mx-1">
                <input
                  type="text"
                  v-model="tokenAddress"
                  placeholder="Address"
                  class="form-control"
                />
              </div>

              <div class="flex-fill text-start">
                <h3 class="text-start d-inline-block me-2">
                  <b id="balanceOfElement"></b>
                </h3>
              </div>
            </div>
          </div>
          <div>
            <button
              v-if="connectedWallet"
              class="btn btn-primary"
              type="button"
              @click="getBalanceOf(connectedWallet.accounts[0].address)"
            >
              CALL
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill">
            <div class="d-flex flex-row">
              <div class="me-3">
                <h3 class="text-start">TokenOfOwnerByIndex</h3>
              </div>
              <div class="mx-1">
                <input
                  type="text"
                  v-model="tokenAddress"
                  placeholder="Address"
                  class="form-control"
                />
              </div>
              <div class="mx-1">
                <input
                  type="text"
                  v-model="tokenIndex"
                  placeholder="tokenIndex"
                  class="form-control"
                />
              </div>
              <div class="flex-fill text-start">
                <h3 class="text-start d-inline-block me-2">
                  <b id="tokenOfOwnerByIndexfElement"></b>
                </h3>
              </div>
            </div>
          </div>
          <div>
            <button
              v-if="tokenAddress && tokenIndex"
              class="btn btn-primary"
              type="button"
              @click="getTokenOfOwnerByIndex(tokenAddress, tokenIndex)"
            >
              CALL
            </button>
          </div>
        </div>

        <hr /> -->

        <div class="d-flex flex-row">
          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">Select variable:</h3>
            <select
              class="form-control form-select d-inline-block"
              style="max-width: 200px"
              v-model="selectedVariable"
              placeholder="select variable"
            >
              <option v-for="item in variables" :value="item" :key="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">
              Current value:<b>{{ selectedVariableValue }}</b>
            </h3>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">MintCount:</h3>
            <div class="d-inline-block">
              <input
                type="text"
                v-model="address"
                placeholder="address"
                class="form-control"
              />
            </div>
          </div>

          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">
              <b id="mintCountElement"></b>
            </h3>
          </div>

          <div class="flex-shrink">
            <button class="btn btn-primary" type="button" @click="getMintCount">
              CALL
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row">
          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">MintAddressTrack:</h3>
            <div class="d-inline-block">
              <input
                type="text"
                v-model="address"
                placeholder="address"
                class="form-control"
              />
            </div>
          </div>

          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">
              <b id="mintAddressTrackElement"></b>
            </h3>
          </div>

          <div class="flex-shrink">
            <button
              class="btn btn-primary"
              type="button"
              @click="getMintAddressTrack"
            >
              CALL
            </button>
          </div>
        </div>

        <hr />

        <div class="d-flex flex-row w-100">
          <div class="flex-fill text-start">
            <h3 class="text-start d-inline-block me-2">LotteryWinner:</h3>
            <div class="d-inline-block">
              <input
                type="text"
                v-model="tokenId"
                placeholder="tokenId"
                class="form-control"
              />
            </div>
          </div>

          <div
            class="flex-fill text-start"
            style="max-width: 400px; word-break: break-all"
          >
            <h3 class="text-start d-inline-block me-2">
              <b id="lotteryWinnerElement"></b>
            </h3>
          </div>

          <div class="flex-shrink">
            <button
              class="btn btn-primary"
              type="button"
              @click="getLotteryWinner"
            >
              CALL
            </button>
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import web3 from "web3";
import { onboardInit } from "./service/onboard";
import { getContract } from "./service/web3";
import { useOnboard } from "@web3-onboard/vue";

export default {
  name: "App",
  components: {},
  watch: {
    connectedWallet: function () {
      this.address = this.connectedWallet.accounts[0].address;
      this.initContract();
      this.getUserNFTs();
    },
    selectedVariable: function (val) {
      this.selectedVariableValue = "";
      this.getVariableByName(val);
    },
  },
  data() {
    return {
      contract: null,
      address: null,
      tokenId: null,
      d1Address: null,
      d2Address: null,
      tokenNumbersOfOwner: null,
      tokenIndexesOfOwner: [],
      tokenAddress: null,
      tokenIndex: null,
      selectedVariable: null,
      selectedVariableValue: null,
      variables: [
        "mintLimitPerRound",
        "revealAmount",
        "totalAmount",
        "mintableAmount",
        "totalRound",
        "currentRound",
        "priceStep",
        "currentPrice",
        "prizePool",
        //"tokens",
        "baseTokenURI",
        //"mintCount",
        //"mintAddressTrack",
        //"lotteryWinner",
      ],
    };
  },
  methods: {
    initContract: async function () {},
    nextRound: async function () {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const receipt = await contract.methods
          .startGame()
          .send({
            from: this.connectedWallet.accounts[0].address,
            value: 0,
            gas: 900000,
          })
          .on("transactionHash", (txHash) => {
            console.log(txHash);
          });
        console.log(receipt);
      } catch (e) {
        console.log(e);
      }
    },
    buyNFT: async function () {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const receipt = await contract.methods
          .mintNFT()
          .send({
            from: this.connectedWallet.accounts[0].address,
            value: new web3(this.connectedWallet.provider).utils.toWei(
              "0.005",
              "ether"
            ),
            gas: 900000,
          })
          .on("transactionHash", (txHash) => {
            console.log(txHash);
          });
        console.log(receipt);
      } catch (e) {
        console.log(e);
      }
    },
    lottery: async function () {
      if (
        (this.d1Address == null || this.d1Address == "") &&
        (this.d2Address == null || this.d2Address == "")
      ) {
        return;
      }

      try {
        const contract = await getContract(this.connectedWallet.provider);
        const receipt = await contract.methods
          .lottery(this.d1Address, this.d2Address)
          .send({
            from: this.connectedWallet.accounts[0].address,
            value: 0,
            gas: 900000,
          })
          .on("transactionHash", (txHash) => {
            console.log(txHash);
          });
        console.log(receipt);
      } catch (e) {
        console.log(e);
      }
    },
    getVariableByName: async function (name) {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods[name]().call();
        this.selectedVariableValue = answer;
      } catch (e) {
        console.log(e);
      }
    },
    getMintCount: async function () {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods.mintCount(this.address).call();
        document.getElementById("mintCountElement").innerHTML =
          JSON.stringify(answer);
      } catch (e) {
        console.log(e);
      }
    },
    getMintAddressTrack: async function () {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods
          .mintAddressTrack(this.address)
          .call();
        document.getElementById("mintAddressTrackElement").innerHTML =
          JSON.stringify(answer);
      } catch (e) {
        console.log(e);
      }
    },
    getLotteryWinner: async function () {
      try {
        var result = null;
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods
          .lotteryWinner(this.tokenId)
          .call();
        if (answer) {
          result = JSON.stringify(answer);
        } else {
          result = "null";
        }
        document.getElementById("lotteryWinnerElement").innerHTML = result;
      } catch (e) {
        console.log(e);
      }
    },
    getBalanceOf: async function (address) {
      try {
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods.balanceOf(address).call();
        //this.tokenNumbersOfOwner = answer;
        //document.getElementById("balanceOfElement").innerHTML = parseInt(answer);
        return parseInt(answer);
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
    getTokenOfOwnerByIndex: async function (address, index) {
      try {
        var result = null;
        const contract = await getContract(this.connectedWallet.provider);
        const answer = await contract.methods
          .tokenOfOwnerByIndex(address, index)
          .call();
        console.log(answer);
        if (answer) {
          result = answer;
        } else {
          result = "null";
        }
        // document.getElementById("tokenOfOwnerByIndexfElement").innerHTML =
        parseInt(result);

        return parseInt(result);
      } catch (e) {
        console.log(e);
        // document.getElementById("tokenOfOwnerByIndexfElement").innerHTML =
        ("ERROR");
        return null;
      }
    },
    getUserNFTs: async function () {
      if (this.address) {
        console.log("START");
        let balance = await this.getBalanceOf(this.address);
        console.log(balance);
        document.getElementById("connectedWalletNFTs").innerHTML = "";
        for (let index = 0; index < parseInt(balance); index++) {
          const nftIndex = await this.getTokenOfOwnerByIndex(
            this.address,
            index
          );
          if (nftIndex != null) {
            console.log("index:" + nftIndex);

            const url =
              "https://gateway.pinata.cloud/ipfs/QmX6Czd8JA8J46eQ1u4UAFogFkiQL7EwSggcMn2351kKdf/" +
              parseInt(nftIndex) +
              ".png";
            document
              .getElementById("connectedWalletNFTs")
              .insertAdjacentHTML(
                "beforeend",
                '<div class="p-3 d-inline-block"><img width="100px" height="auto" src="' +
                  url +
                  '" /></div>'
              );
          }
        }
      }
    },
  },
  setup() {
    onboardInit();

    const {
      wallets,
      connectWallet,
      disconnectConnectedWallet,
      connectedWallet,
    } = useOnboard();

    const connect = async () => connectWallet();

    return {
      wallets,
      connect,
      disconnectConnectedWallet,
      connectedWallet,
    };
  },
  mounted() {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

import web3 from "web3";
import abi from "../contract/abi.json";

const initWeb3 = async (provider) => {
  return await new web3(provider);
};

const getContract = async (provider) => {
  const web3 = await initWeb3(provider);
  return new web3.eth.Contract(abi, process.env.VUE_APP_CONTRACT);
};

export { getContract };
